import React, { useState } from "react";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import Navigation from "../Components/Navigation";
import ScrollToTop from "../Components/ScrollToTop";
import Footer from "../Components/Footer";
import {
  Campaigns,
  Demogame,
  Homepage,
  Login,
  Logs,
  Maingame,
  Profile,
  Purchase,
  Upcominggames,
} from ".";
import AboutUs from "../Screens/AboutUs";
import Page404 from "../Screens/Page404";
import LiveDemoGame from "./LiveDemogame";
import PrivacyPolicy from "./PrivacyPolicy";
import RefundPolicy from "./RefundPolicy";
import TermsCondition from "./Terms";
import { auth } from "../Components/Firebase";
import Loader from "../Components/Loader";
import Notification from "../Components/Notification";

function RequireAuth({ children }) {
  let location = useLocation();

  if (!auth.currentUser) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  return children;
}

function App() {
  const [isAuthReady, setIsAuthReady] = useState(false);

  auth.onAuthStateChanged((user) => {
    setIsAuthReady(true);
  });

  return (
    <div className="App">
      <ScrollToTop>
        {!isAuthReady && <Loader />}
        {isAuthReady && <Navigation />}
        {isAuthReady && (
          <>
            {auth.currentUser && <Notification />}
            <Routes>
              <Route
                exact
                path="/"
                element={
                  <RequireAuth>
                    <Campaigns />
                  </RequireAuth>
                }
              />
              <Route exact path="/login" element={<Login />} />
              <Route
                exact
                path="/campaigns/:id"
                element={
                  <RequireAuth>
                    <Homepage />
                  </RequireAuth>
                }
              />
              <Route
                exact
                path="/profile"
                element={
                  <RequireAuth>
                    <Profile />
                  </RequireAuth>
                }
              />
              <Route
                exact
                path="/campaigns"
                element={
                  <RequireAuth>
                    <Campaigns />
                  </RequireAuth>
                }
              />
              <Route
                exact
                path="/upcoming-games"
                element={
                  <RequireAuth>
                    <Upcominggames />
                  </RequireAuth>
                }
              />
              <Route exact path="/game/:id/logs" element={<Logs />} />
              {/* <PrivateRoute exact path="/logs" component={Logs} /> Remove after testing */}
              <Route
                exact
                path="/game/:id"
                element={
                  <RequireAuth>
                    <Maingame />
                  </RequireAuth>
                }
              />
              <Route exact path="/demo" element={<Demogame />} />
              <Route exact path="/privacypolicy" element={<PrivacyPolicy />} />
              <Route exact path="/refundpolicy" element={<RefundPolicy />} />
              <Route
                exact
                path="/termsandcondition"
                element={<TermsCondition />}
              />
              <Route
                exact
                path="/purchase/:id"
                element={
                  <RequireAuth>
                    <Purchase />
                  </RequireAuth>
                }
              />
              <Route
                exact
                path="/livedemo"
                element={
                  <RequireAuth>
                    <LiveDemoGame />
                  </RequireAuth>
                }
              />
              <Route exact path="/aboutus" element={<AboutUs />} />
              <Route exact path="*" element={<Page404 />} />
            </Routes>
          </>
        )}
        <Footer />
      </ScrollToTop>
    </div>
  );
}

export default App;
