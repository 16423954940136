import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getDatabase } from "firebase/database";
import { getFirestore } from "firebase/firestore";
import { getMessaging, onMessage, getToken } from "firebase/messaging";
import { onBackgroundMessage } from "firebase/messaging/sw";

const config = {
  apiKey: process.env.REACT_APP_APIKEY,
  authDomain: process.env.REACT_APP_AUTHDOMAIN,
  databaseURL: process.env.REACT_APP_DB,
  projectId: process.env.REACT_APP_PID,
  storageBucket: process.env.REACT_APP,
  messagingSenderId: process.env.REACT_APP_SID,
  appId: process.env.REACT_APP_APPID,
  measurementId: process.env.REACT_APP_MID,
};

export const app = initializeApp(config);
export const firestore = getFirestore(app);
export const auth = getAuth(app);
export const database = getDatabase(app);
export const messaging = getMessaging(app);

export const askForPermissioToReceiveNotifications = async () => {
  try {
    const permission = await Notification.requestPermission();
    return permission === "granted" && (await getToken(messaging));
  } catch (error) {
    console.error(error);
  }
};

export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => resolve(payload));
    onBackgroundMessage(messaging, (payload) => resolve(payload));
  });

export default app;
