import React from "react";
import { Button, Card } from "react-bootstrap";
import "../index.css";
import moment from "moment";

const Cards = (props) => {
  let { data, onpress } = props;
  let { endTimestamp, customUI, startTimestamp } = data || {};
  let {
    title,
    promoImageURL,
    prizesWorth,
    prizeDescription,
    primary,
    secondary,
    background,
  } = customUI || {};
  const buttonBg = primary === "DEFAULT" ? "" : primary;
  const textcolor = secondary === "DEFAULT" ? "" : secondary;
  const dateString2 = moment.unix(endTimestamp).format("DD MMM h:mm a");
  const dateString = moment.unix(startTimestamp).format("DD MMM h:mm a");
  return (
    <Card
      className="cardcampaign"
      onClick={onpress}
      style={
        background === "DEFAULT"
          ? { background: "rgba(0, 0, 0,0.3)" }
          : background && background.length === 1
          ? { background: background && background[0] }
          : {
              background: `linear-gradient(${background && background[0]},${
                background && background[1]
              })`,
            }
      }
    >
      <Card.Img
        variant="top"
        src={promoImageURL}
        style={{ height: "12rem", objectFit: "contain", marginTop: 20 }}
      />
      <Card.Body>
        <Card.Title className="whitetext" style={{ color: textcolor }}>
          {title}
        </Card.Title>

        <Card.Subtitle className="mb-2 whitetext2" style={{ color: textcolor }}>
          {prizeDescription}
        </Card.Subtitle>
        <Card.Subtitle className="mb-2 whitetext2" style={{ color: textcolor }}>
          Active from {dateString} to {dateString2}
        </Card.Subtitle>

        <Button
          className="BuyButton bouncy1"
          style={{ background: buttonBg, color: textcolor }}
        >
          Prize Worth {"\u20B9"}
          {prizesWorth}
        </Button>
      </Card.Body>
    </Card>
  );
};

export default Cards;
